var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseSubSection', {
    attrs: {
      "id": _vm.section.key,
      "is-visible": _vm.isSectionVisible,
      "is-modified": _vm.isDataChanged,
      "section": _vm.section,
      "clientCanEdit": _vm.IS_CLIENT
    },
    on: {
      "onSectionSaveClick": _vm.save
    },
    scopedSlots: _vm._u([{
      key: "section",
      fn: function fn() {
        return [_c('v-card-text', {
          staticClass: "mt-4"
        }, [_c('div', {
          staticClass: "mb-5"
        }, [_c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "Last name",
            "placeholder": "Doe",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.last_name,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "last_name", $$v);
            },
            expression: "personalDetails.last_name"
          }
        })], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "First name",
            "placeholder": "Joe",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.first_name,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "first_name", $$v);
            },
            expression: "personalDetails.first_name"
          }
        })], 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Title")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.app_title,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "app_title", $$v);
            },
            expression: "personalDetails.app_title"
          }
        }, _vm._l(_vm.appTitleOptions, function (appTitleItem) {
          return _c('v-radio', {
            key: appTitleItem.value,
            attrs: {
              "value": appTitleItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(appTitleItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "Middle name",
            "placeholder": "J.D.",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.middle_name,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "middle_name", $$v);
            },
            expression: "personalDetails.middle_name"
          }
        })], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "Preferred name",
            "placeholder": "B",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.preffered_name,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "preffered_name", $$v);
            },
            expression: "personalDetails.preffered_name"
          }
        })], 1), _c('v-menu', {
          ref: "birthdayMenu",
          attrs: {
            "close-on-content-click": false,
            "transition": "scale-transition",
            "offset-y": "",
            "min-width": "auto",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-text-field', _vm._g(_vm._b({
                directives: [{
                  name: "mask",
                  rawName: "v-mask",
                  value: '####-##-##',
                  expression: "'####-##-##'"
                }],
                attrs: {
                  "dense": "",
                  "rules": _vm.dateRules,
                  "label": "Date of birth",
                  "prepend-icon": "mdi-calendar",
                  "hint": "YYYY-MM-DD",
                  "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
                },
                on: {
                  "blur": _vm.dateUpdate
                },
                model: {
                  value: _vm.dateData,
                  callback: function callback($$v) {
                    _vm.dateData = $$v;
                  },
                  expression: "dateData"
                }
              }, 'v-text-field', attrs, false), on))];
            }
          }]),
          model: {
            value: _vm.birthdayMenu,
            callback: function callback($$v) {
              _vm.birthdayMenu = $$v;
            },
            expression: "birthdayMenu"
          }
        }, [_c('v-date-picker', {
          attrs: {
            "active-picker": _vm.birthdayActivePicker,
            "max": _vm.birthdayMaxDay,
            "min": "1950-01-01",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          on: {
            "update:activePicker": function updateActivePicker($event) {
              _vm.birthdayActivePicker = $event;
            },
            "update:active-picker": function updateActivePicker($event) {
              _vm.birthdayActivePicker = $event;
            },
            "change": _vm.saveBirthday
          },
          model: {
            value: _vm.personalDetails.birth_date,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "birth_date", $$v);
            },
            expression: "personalDetails.birth_date"
          }
        })], 1), _c('p', {
          staticClass: "age"
        }, [_c('b', [_vm._v("Age:")]), _vm._v(" " + _vm._s(_vm.age))]), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Marital Status")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.marital_status,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "marital_status", $$v);
            },
            expression: "personalDetails.marital_status"
          }
        }, _vm._l(_vm.maritalStatusOptions, function (maritalStatusItem) {
          return _c('v-radio', {
            key: maritalStatusItem.value,
            attrs: {
              "value": maritalStatusItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(maritalStatusItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Gender")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.gender,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "gender", $$v);
            },
            expression: "personalDetails.gender"
          }
        }, _vm._l(_vm.genderOptions, function (genderItem) {
          return _c('v-radio', {
            key: genderItem.value,
            attrs: {
              "value": genderItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(genderItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("First Home Buyer")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.first_home_buyer,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "first_home_buyer", $$v);
            },
            expression: "personalDetails.first_home_buyer"
          }
        }, _vm._l(_vm.firstHomeBuyerOptions, function (firstHomeBuyerItem) {
          return _c('v-radio', {
            key: firstHomeBuyerItem.value,
            attrs: {
              "value": firstHomeBuyerItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(firstHomeBuyerItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("NZ Residency Status")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.nz_residency_status,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "nz_residency_status", $$v);
            },
            expression: "personalDetails.nz_residency_status"
          }
        }, _vm._l(_vm.nzResidencyStatusOptions, function (nzResidencyStatusItem) {
          return _c('v-radio', {
            key: nzResidencyStatusItem.value,
            attrs: {
              "value": nzResidencyStatusItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(nzResidencyStatusItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-expand-transition', [_vm.isNotCitizen ? _c('v-autocomplete', {
          attrs: {
            "autocomplete": "nofill",
            "label": "Citizenship (if no NZ)",
            "placeholder": "USA",
            "items": _vm.countriesItems,
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.citizenship,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "citizenship", $$v);
            },
            expression: "personalDetails.citizenship"
          }
        }) : _vm._e()], 1), _c('v-expand-transition', [_vm.isNotCitizen ? _c('v-autocomplete', {
          attrs: {
            "autocomplete": "nofill",
            "label": "Country of Residence",
            "placeholder": "Canada",
            "items": _vm.countriesItems,
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.country_of_residence,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "country_of_residence", $$v);
            },
            expression: "personalDetails.country_of_residence"
          }
        }) : _vm._e()], 1), _c('v-expand-transition', [_vm.isNotCitizen ? _c('v-autocomplete', {
          attrs: {
            "autocomplete": "nofill",
            "label": "Country of Birth",
            "placeholder": "Canada",
            "items": _vm.countriesItems,
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.country_of_birth,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "country_of_birth", $$v);
            },
            expression: "personalDetails.country_of_birth"
          }
        }) : _vm._e()], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "Email",
            "placeholder": "hello@example.com",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.email,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "email", $$v);
            },
            expression: "personalDetails.email"
          }
        })], 1), _c('v-expand-transition', [_c('v-text-field', {
          attrs: {
            "label": "Phone",
            "placeholder": "02101112233",
            "outlined": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "phone", $$v);
            },
            expression: "personalDetails.phone"
          }
        })], 1), _c('v-radio-group', {
          attrs: {
            "row": true
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', [_c('h3', [_vm._v("Have you or any co-applicant had any previous credit issues or bunkruptcy?")])])];
            },
            proxy: true
          }]),
          model: {
            value: _vm.personalDetails.bunkruptcy,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "bunkruptcy", $$v);
            },
            expression: "personalDetails.bunkruptcy"
          }
        }, _vm._l(_vm.bunkruptcyOptions, function (bunkruptcyItem) {
          return _c('v-radio', {
            key: bunkruptcyItem.value,
            attrs: {
              "value": bunkruptcyItem.value,
              "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
            },
            scopedSlots: _vm._u([{
              key: "label",
              fn: function fn() {
                return [_c('div', [_vm._v(_vm._s(bunkruptcyItem.text))])];
              },
              proxy: true
            }], null, true)
          });
        }), 1), _c('v-expand-transition', [_vm.isBunkruptcy ? _c('v-textarea', {
          attrs: {
            "outlined": "",
            "label": "Please, provide more details",
            "placeholder": "Details",
            "auto-grow": "",
            "disabled": _vm.FIELDS_DISABLED && !_vm.IS_CLIENT
          },
          model: {
            value: _vm.personalDetails.bunkruptcy_comment,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "bunkruptcy_comment", $$v);
            },
            expression: "personalDetails.bunkruptcy_comment"
          }
        }) : _vm._e()], 1), _c('v-expand-transition', [_c('v-textarea', {
          attrs: {
            "outlined": "",
            "label": "Adviser only comments",
            "auto-grow": ""
          },
          model: {
            value: _vm.personalDetails.adviser_comment,
            callback: function callback($$v) {
              _vm.$set(_vm.personalDetails, "adviser_comment", $$v);
            },
            expression: "personalDetails.adviser_comment"
          }
        })], 1)], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }