<template>
  <BaseSubSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    :clientCanEdit="IS_CLIENT"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-expand-transition>
            <v-text-field
              label="Last name"
              placeholder="Doe"
              outlined
              v-model="personalDetails.last_name"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              label="First name"
              placeholder="Joe"
              outlined
              v-model="personalDetails.first_name"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-radio-group
            v-model="personalDetails.app_title"
            :row="true"
          >
            <template #label>
              <div>
                <h3>Title</h3>
              </div>
            </template>
            <v-radio
              v-for="appTitleItem in appTitleOptions"
              :value="appTitleItem.value"
              :key="appTitleItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ appTitleItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-expand-transition>
            <v-text-field
              label="Middle name"
              placeholder="J.D."
              outlined
              v-model="personalDetails.middle_name"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              label="Preferred name"
              placeholder="B"
              outlined
              v-model="personalDetails.preffered_name"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-menu
            ref="birthdayMenu"
            v-model="birthdayMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="FIELDS_DISABLED && !IS_CLIENT"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                dense
                v-mask="'####-##-##'"
                v-model="dateData"
                :rules="dateRules"
                label="Date of birth"
                prepend-icon="mdi-calendar"
                hint="YYYY-MM-DD"
                v-bind="attrs"
                v-on="on"
                :disabled="FIELDS_DISABLED && !IS_CLIENT"
                @blur="dateUpdate"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="personalDetails.birth_date"
              :active-picker.sync="birthdayActivePicker"
              :max="birthdayMaxDay"
              min="1950-01-01"
              @change="saveBirthday"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            />
          </v-menu>

          <p class="age"><b>Age:</b> {{ age }}</p>

          <v-radio-group
            v-model="personalDetails.marital_status"
            :row="true"
          >
            <template #label>
              <div>
                <h3>Marital Status</h3>
              </div>
            </template>
            <v-radio
              v-for="maritalStatusItem in maritalStatusOptions"
              :value="maritalStatusItem.value"
              :key="maritalStatusItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ maritalStatusItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="personalDetails.gender"
            :row="true"
          >
            <template #label>
              <div>
                <h3>Gender</h3>
              </div>
            </template>
            <v-radio
              v-for="genderItem in genderOptions"
              :value="genderItem.value"
              :key="genderItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ genderItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="personalDetails.first_home_buyer"
            :row="true"
          >
            <template #label>
              <div>
                <h3>First Home Buyer</h3>
              </div>
            </template>
            <v-radio
              v-for="firstHomeBuyerItem in firstHomeBuyerOptions"
              :value="firstHomeBuyerItem.value"
              :key="firstHomeBuyerItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ firstHomeBuyerItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-radio-group
            v-model="personalDetails.nz_residency_status"
            :row="true"
          >
            <template #label>
              <div>
                <h3>NZ Residency Status</h3>
              </div>
            </template>
            <v-radio
              v-for="nzResidencyStatusItem in nzResidencyStatusOptions"
              :value="nzResidencyStatusItem.value"
              :key="nzResidencyStatusItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ nzResidencyStatusItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-expand-transition>
            <v-autocomplete
              v-if="isNotCitizen"
              autocomplete="nofill"
              label="Citizenship (if no NZ)"
              placeholder="USA"
              v-model="personalDetails.citizenship"
              :items="countriesItems"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
            </v-autocomplete>
          </v-expand-transition>

          <v-expand-transition>
            <v-autocomplete
              v-if="isNotCitizen"
              autocomplete="nofill"
              label="Country of Residence"
              placeholder="Canada"
              v-model="personalDetails.country_of_residence"
              :items="countriesItems"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
            </v-autocomplete>
          </v-expand-transition>

          <v-expand-transition>
            <v-autocomplete
              v-if="isNotCitizen"
              autocomplete="nofill"
              label="Country of Birth"
              placeholder="Canada"
              v-model="personalDetails.country_of_birth"
              :items="countriesItems"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
            </v-autocomplete>
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              label="Email"
              placeholder="hello@example.com"
              outlined
              v-model="personalDetails.email"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-expand-transition>
            <v-text-field
              label="Phone"
              placeholder="02101112233"
              outlined
              v-model="personalDetails.phone"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-text-field>
          </v-expand-transition>

          <v-radio-group
            v-model="personalDetails.bunkruptcy"
            :row="true"
          >
            <template #label>
              <div>
                <h3>Have you or any co-applicant had any previous credit issues or bunkruptcy?</h3>
              </div>
            </template>
            <v-radio
              v-for="bunkruptcyItem in bunkruptcyOptions"
              :value="bunkruptcyItem.value"
              :key="bunkruptcyItem.value"
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            >
              <template #label>
                <div>{{ bunkruptcyItem.text }}</div>
              </template>
            </v-radio>
          </v-radio-group>

          <v-expand-transition>
            <v-textarea
              v-if="isBunkruptcy"
              outlined
              label="Please, provide more details"
              placeholder="Details"
              v-model="personalDetails.bunkruptcy_comment"
              auto-grow
              :disabled="FIELDS_DISABLED && !IS_CLIENT"
            ></v-textarea>
          </v-expand-transition>

          <v-expand-transition>
            <v-textarea
              outlined
              label="Adviser only comments"
              v-model="personalDetails.adviser_comment"
              auto-grow
            ></v-textarea>
          </v-expand-transition>
        </div>
      </v-card-text>
    </template>
  </BaseSubSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSubSection from '@/components/SubApplications/BaseSubSection.vue';
import countries from '@/services/countries';

import { dateRequired, dateValidation } from '@/utils/validation';

export default {
  name: 'PersonalDetailsSection',

  components: {
    BaseSubSection,
  },

  mixins: [SectionMixin],

  props: {
    parentId: {
      type: String,
    },

    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dateData: null,

      isSectionVisible: false,

      initialPersonalDetails: {},

      personalDetails: {},

      appTitleOptions: [
        {
          text: 'Mr',
          value: 'Mr',
        },
        {
          text: 'Mrs',
          value: 'Mrs',
        },
        {
          text: 'Ms',
          value: 'Ms',
        },
        {
          text: 'Miss',
          value: 'Miss',
        },
        {
          text: 'Dr',
          value: 'Dr',
        },
      ],

      maritalStatusOptions: [
        {
          text: 'Married',
          value: 'married',
        },
        {
          text: 'Defacto',
          value: 'defacto',
        },
        {
          text: 'Single',
          value: 'single',
        },
      ],

      genderOptions: [
        {
          text: 'Male',
          value: 'male',
        },
        {
          text: 'Female',
          value: 'female',
        },
      ],

      firstHomeBuyerOptions: [
        {
          text: 'Yes',
          value: 'yes',
        },
        {
          text: 'No',
          value: 'no',
        },
      ],

      nzResidencyStatusOptions: [
        {
          text: 'Citizen',
          value: 'Citizen',
        },
        {
          text: 'Resident',
          value: 'Resident',
        },
        {
          text: 'Permanent Resident',
          value: 'Permanent Resident',
        },
        {
          text: 'Non Resident',
          value: 'Non Resident',
        },
      ],

      bunkruptcyOptions: [
        {
          text: 'Yes',
          value: 'yes',
        },
        {
          text: 'No',
          value: 'no',
        },
      ],

      birthdayMenu: null,

      birthdayActivePicker: null,

      dateRules: [
        dateRequired('Date of birth'),
        dateValidation('Date of birth'),
      ],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED', 'IS_CLIENT']),

    age() {
      const birth = this.personalDetails.birth_date;

      const isBirhDate = Boolean(birth);

      if (isBirhDate) {
        const now = new Date();
        const birthDate = new Date(birth);

        let age = now.getFullYear() - birthDate.getFullYear();
        const months = now.getMonth() - birthDate.getMonth();

        if (months < 0 || (months === 0 && now.getDate() < birthDate.getDate())) {
          age -= 1;
        }

        return `${age} years`;
      }

      return 'Not available';
    },

    birthdayMaxDay() {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000))
        .toISOString().substr(0, 10);
    },

    countriesItems() {
      return countries.getCountriesAsArrayOfNames();
    },

    isNotCitizen() {
      const isNotCitizen = this.personalDetails.nz_residency_status?.toLowerCase() !== 'citizen';

      return isNotCitizen;
    },

    isBunkruptcy() {
      const isBunkruptcy = this.personalDetails.bunkruptcy?.toLowerCase() === 'yes';

      return isBunkruptcy;
    },
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialPersonalDetails = clone(this.existedData);
    this.personalDetails = clone(this.existedData);
  },

  watch: {
    'personalDetails.birth_date': {
      handler(val) {
        this.dateData = val;
      },
    },

    birthdayMenu() {
      setTimeout(() => {
        this.birthdayActivePicker = 'YEAR';
      }, 0);
    },
  },

  methods: {
    async dateUpdate(event) {
      const value = await event.target.value;

      const isValueValid = Boolean(value.length === 10);

      if (isValueValid) {
        this.personalDetails.birth_date = this.$options.filters.dateISOFormatter(value);
      }
    },

    saveBirthday(date) {
      this.$refs.birthdayMenu.save(date);
    },
  },
};
</script>

<style scoped>
.age {
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
</style>
